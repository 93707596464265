<template>
  <v-app-bar
    id="default-app-bar"
    app
    fixed
    class="v-bar--underline v-app-bar-tourism"
    :style="{background: $vuetify.theme.themes[theme].appBarBG}"
    :elevation="1"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="font-weight-bold text-h3 page-title"
      v-text="name"
    />

    <v-spacer />

    <default-search class="hidden-sm-and-down" />

    <default-go-home />

    <default-notifications />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from 'vuex-pathify'

export default {
  name: 'DefaultBar',

  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
    DefaultNotifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        './widgets/Notifications'
      ),
    DefaultSearch: () =>
      import(
        /* webpackChunkName: "default-search" */
        './widgets/Search'
      )
  },

  computed: {
    ...sync('app', ['drawer', 'mini']),
    name: get('route/name'),

    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },

  mounted () {
    this.replaceTitleToChinese()
    console.log(this.$route)
  },

  updated () {
    this.replaceTitleToChinese()
  },

  methods: {
    // 判斷 route name 並將其轉換為中文
    replaceTitleToChinese: function () {
      // if else 寫法
      const title = document.querySelector('.v-toolbar__title')
      const routeName = this.$route.name
      if (routeName === 'AllProjects') {
        title.innerHTML = '專案預覽'
      } else if (routeName === 'Dashboard') {
        title.innerHTML = '綜合資訊'
      } else if (routeName === 'CloudControl') {
        title.innerHTML = '雲端控制'
      } else if (routeName === 'AlertNotice') {
        title.innerHTML = '告警通知'
      } else if (routeName === 'OperatingState') {
        title.innerHTML = '運轉狀態'
      } else if (routeName === 'SensingData') {
        title.innerHTML = '感測資料'
      } else if (routeName === 'Log') {
        title.innerHTML = '操作紀錄'
      } else if (routeName === 'MapGoogle') {
        title.innerHTML = 'Google 地圖'
      } else if (routeName === 'MapEcharts') {
        title.innerHTML = 'Echarts Geo 地圖'
      } else if (routeName === 'MapOpenlayer') {
        title.innerHTML = 'OpenLayer GIS 地圖'
      } else if (routeName === 'MapLeaflet') {
        title.innerHTML = 'Leaflet GIS 地圖'
      } else if (routeName === 'MapArcGIS') {
        title.innerHTML = 'ArcGIS 地圖'
      } else {
        title.innerHTML = '頁面標題'
      }
    }
  }
}
</script>

<style scoped>
.page-title {
  animation-name: fade-in;
  animation-duration: 2s;
  /* color: black; */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>
